.opc-wrapper{
  width: 100%;
  @media (min-width: 768px) {
    width: 50%!important;
  }
}
.opc-sidebar{
  width: 100%;
  @media (min-width: 768px) {
    width: 45%!important;
  }
}
