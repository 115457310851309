.logo {
    float: none;
    margin-right: auto;
    margin-left: auto;
    display: block;

    img {
        max-width: 150px;
        margin-left: 30px;

        @media (min-width: 375px) {
            margin-left: 35px;
        }
        @media (min-width: 480px) {
            max-width: 240px;
            margin-left: 40px;
        }
        @media (min-width: 1024px) {
            max-width: 240px;
            margin-left: -80px;
        }
        @media (min-width: 1280px) {

            max-width: 350px;
            margin-left: -10px;
        }
    }
}

.block-search {
    display: none;
}

.ui-menu {
    @media (min-width: 768px) {
        display: flex !important;
    }
}

.section-item-content {
    background: white;
}

.header.content {
    display: flex;
    max-width: 900px;
}

.minicart-wrapper {
    @media (min-width: 768px) {

        margin-top: auto;
        margin-bottom: auto;
    }

}

.block-title {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;

    text-transform: uppercase;

    strong {
        text-transform: uppercase;
        font-weight: 500 !important;
        font-size: 45px !important;
        color: black;
    }
}

.page-title {
    font-size: 45px;
    font-weight: 500;
}

.info-block {


    padding: 20px;
    @media (min-width: 600px) {
        margin: 20px;
        background: url(../images/apla_left.png) no-repeat;
        background-size: cover;
        padding: 40px;
    }
    color: rgba(70, 136, 125, 1);

    &:first-of-type {

        @media (min-width: 768px) {
            margin-left: 17px;
        }
    }

    p {
        font-family: 'Quicksand', "Open Sans", sans-serif;
        font-weight: 400;
        font-size: 14px;
    }

    h4 {
        margin: 0 !important;
        padding: 0 !important;
        font-weight: 500;
        font-size: 45px;
        text-transform: uppercase;
    }

    h2 {
        font-weight: 500;
        margin: 0 !important;
        //padding: 10px 0 !important;
    }

    &:last-child {
        color: rgba(100, 108, 77, 1);
        margin-top: 40px;
        @media (min-width: 600px) {
            margin-top: 20px;
            background: url(../images/apla_right.png) no-repeat;
            background-size: cover;
        }

    }
}

.product-items.widget-product-grid {
    text-align: center;
}

.widget-product-grid {
    .swatch-attribute {
        display: none;
    }
}

.product-item-actions {
    display: block;
    @media (min-width: 992px) {
        display: none;
    }
}

.product-item-name {
    text-align: center;
    margin: 0;
    padding: 0;

    a {

        font-size: 25px;
        text-align: center;
        color: rgba(100, 108, 77, 1);
    }
}

.price-box {
    text-align: center;
    font-size: 35px;
    color: rgba(70, 136, 125, 1) !important;
    margin: 0;

    span {
        font-family: 'Quicksand', "Open Sans", sans-serif;
        font-weight: normal !important;
        display: block;
        font-size: 20px !important;
        color: rgba(70, 136, 125, 1) !important;
    }
}

@media (min-width: 768px) {
    .products-grid {

        .swatch-attribute {
            display: none;
        }

        .product-item-inner {
            display: none;
        }
    }
}

//}

.widget.block {
    h1, h2, h3, h4 {
        font-weight: 500;
        font-size: 35px;
    }
}

.panel.header {
    .language-switch {
        @media (min-width: 768px) {

            display: none !important;
        }
    }

    .switcher-language {

        @media (min-width: 768px) {
            display: none;
        }
    }
}

.header.content {
    .switcher-language {
        display: none;
    }

    .language-switch {

        display: none;

        @media (min-width: 768px) {

            display: flex;
        }
    }
}

.language-switch {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0;
    padding: 0;

    li {
        list-style: none;

        &:last-of-type {
            margin-left: 20px;
        }
    }
}


#product-addtocart-button, .action.tocart.primary {
    background: url(../images/button.png) no-repeat;
    padding-left: 40px;
    padding-right: 40px;
    background-size: auto 100%;
    background-position: center center;
    border: none;
}

//.header{
//    display: flex;
//    flex:1;
//}
.navigation {
    max-width: 70%;
}

.category-view {
    .category-image {
        display: none;
    }
}

.navigation, .page-bottom {
    max-width: 900px !important;
    margin: 0 auto;
    background: white !important;

    li, a, img, span {
        margin-bottom: 0;
        height: auto;
        padding: 0;
    }

    li {
        &:not(.first) {
            margin-top: -5px;
        }
    }

    @media (min-width: 768px) {
        //padding-top: 50px;
        li, a, img, span {
            margin-bottom: 0 !important;
            height: auto;
            padding: 0;

        }
        li {
            img {
                @media (min-width: 992px) {
                    //padding: 14px 0 14px 15px;
                }
            }
        }
    }
}

.catalog-category-view {
    .page-title-wrapper {
        display: none;
    }

    .breadcrumbs {
        display: none;
    }

    .columns {
        //display: flex;
        .column {
            &.main {
                width: 100%;
            }
        }

        .block-title.filter-title, .block-subtitle.filter-subtitle, .breadcrumbs, .block-subtitle {
            display: none;
        }

        .swatch-option {
            margin: 0 10px 0 0;
            padding: 8px;
        }

        .filter-options {
            display: block;
            @media (min-width: 992px) {

                display: flex;
                justify-content: space-between;
            }
        }

        .filter-content, .filter-current {
            display: flex;
            justify-content: space-between;

            .items {
                display: flex;

                .item {
                    display: flex;
                }
            }
        }

        .filter {
            margin-bottom: 0;
        }

        .action.clear.filter-clear {
            display: none;
        }

        dt, dd {
            display: block;
            align-self: center;

            &:first-of-type {
                display: none;
            }
        }

        dt {
            margin-bottom: 25px;
            padding-right: 10px;
        }

        .toolbar.toolbar-products {
            display: none;
        }

        .sidebar {
            width: 100%;
        }
    }
}


.page-products {
    .actions-primary {
        display: block;
        width: 100%;

        .tocart {
            width: 100%;
            display: block;
            background: url(../images/button.png) no-repeat;
            padding: 15px 40px;
            background-size: auto 100%;
            background-position: center center;
            border: none;
            font-size: 25px;
        }
    }

    .actions-secondary {
        display: none !important;
    }
}

.product-image-photo {
    mask: url("../images/foto_kwadrat_maska.svg");
    mask-size: cover;
}

.owl-item {
    max-height: 570px;
    overflow: hidden;
    //  mask: url("../images/slider_maska.svg");
    //  mask-size: cover;
}

.nav-sections {
    margin-bottom: 35px;
}

.cms-home, .cms-home-en {
    .nav-sections {
        margin-bottom: 70px !important;
    }

    .page-title-wrapper {
        display: none !important;
    }
}

.product.attribute.sku {
    display: none !important;
}

.totals-tax {
    display: none;
}

.page-title {
    font-weight: 700;
}

.price-label {
    display: none !important;
}

.product-info-price {

    .price-final_price {
        text-align: left;
    }
}

.category-lookbook {
    .category-cms {
        img {
            @media (min-width: 768px) {

                float: left;
                max-width: 48%;
                margin: 1%;
                height: auto;
                &.img-portrait {
                    max-width: 23%;
                }
            }
        }
    }
}

.product-item {
    @media (min-width: 768px) {

        display: inline-block !important;
    }
}

.product-social-links {
    img {
        max-width: 25%;
        margin: 4%;
        height: auto;
        vertical-align: top;
    }
}

#banner-slider-carousel {
    display: none;

    @media (min-width: 1280px) {
        display: block;
    }

    .banner-title {

        top: 210px;
        font-size: 72px;
        left: 0;
        padding: 0.3em !important;

        @media (min-width: 768px) {
            top: 150px;
        }
        @media (min-width: 992px) {

            top: 210px;
            font-size: 96px;
            left: 10%;
            padding: 1em !important;
        }
        background: none !important;
        text-shadow: 0 0 10px #262626;
        z-index: 999999;
        bottom: auto !important;
        opacity: 1 !important;
        line-height: 0.9em;
        font-weight: 500;
        //max-width: 900px;
        text-transform: uppercase;

        small {
            font-size: 30px;
            line-height: 44px;
            @media (min-width: 992px) {
                margin-left: -20%;
                font-size: 50px;
            }
        }

    }
}

.banner-item {

    .img-responsive {
        mask: url("../images/slider_maska_2.svg");
        mask-size: 1440px;
    }
}

.category-cms {
    font-family: 'Quicksand', "Open Sans", sans-serif !important;

    p, ol, li, ul {
        font-family: 'Quicksand', "Open Sans", sans-serif;
        font-size: 14px;
    }
}

.product.overview {
    font-family: 'Quicksand', "Open Sans", sans-serif;
    font-size: 14px;
}

.fairtrade-footer {
    font-family: 'Quicksand', "Open Sans", sans-serif;
    max-width: 200px;

    font-size: 10px;
    line-height: 14px !important;

    small {

        font-size: 10px;
        line-height: 12px !important;
    }

    //line-height: 1em;
}

.catalog-product-view .paypal-button-context-iframe {
    display: none;
}

.cms-home, .cms-home-en {
    .page-main {
        padding: 0 !important;
        max-width: none;
        margin-top: -50px;

        .block {
            margin-top: 50px;
            max-width: 900px;

            @media (min-width: 768px) {

                margin: 0 auto;
            }
        }
    }
}


.footer {
    padding: 10px 30px;

    .page-main {
        max-width: 1100px !important;
        margin: 0 auto;

    }
}

.page-layout-1column, .page-layout-2columns-left {
    &:not(.cms-index-index) {

        .page-main, .breadcrumbs {
            max-width: 900px
        }
    }
}

#tooltip-label {
    display: none;
}

.catalog-product-view {
    @media (min-width: 992px) {

        .product.media {
            width: 50%;
        }

        .product-info-main {
            width: 47%;
        }
    }
}

.nav-open {
    .navigation {
        img {
            width: 100%;
            margin-bottom: 0;


        }
    }
}

p, .note {

    font-size: 14px;
    font-family: 'Quicksand', "Open Sans", sans-serif !important;
    font-weight: 400;
}

.product-item-inner {
    margin-bottom: 30px;
}

.product-item-actions {
    margin-bottom: 20px;
    margin-top: 20px;
}

.opc-estimated-wrapper {
    .minicart-wrapper {
        //display: none;
    }
}

.page-layout-checkout {
    .logo {
        img {
            margin-left: 0;
        }
    }
}

input, select {

    font-family: 'Quicksand', "Open Sans", sans-serif !important;
    height: auto;
}

#block-shipping {
    display: none;
}

.cart-summary .title, .opc .title, .opc .step-title, #opc-sidebar .title, #opc-sidebar .title strong, .shipping-information-title span {
    font-weight: 500;
    font-family: "Amatic SC", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.opc label span, .opc fieldset span, .table-checkout-shipping-method td, .table-checkout-shipping-method td span, .message, #co-payment-form .payment-method-title, #co-payment-form .billing-address-details, .opc-block-summary .table-totals, .shipping-information-content, .shipping-information-content span, .account-nav, .account .box-content {
    font-size: 15px !important;
    font-family: 'Quicksand', "Open Sans", sans-serif !important;
}

.cart-summary, .cart-summary .title, .cart-container .item-info td, .minicart-items .product-item-details {
    font-size: 13px !important;
    font-family: 'Quicksand', "Open Sans", sans-serif !important;
}

.column.main {
    min-height: 0 !important;
}

.contact-index-index .page-title, .contact-index-index .legend {
    display: none !important;
}

.category-lookbook .main-content {
    margin-top: -35px;
}

.filter-current .remove {
    margin-left: 10px;
}

#screen {
    display: none;
}

#bio_ep {
    width: auto !important;
    max-width: 800px !important;
    height: auto !important;
}

#mp-powered {
    display: none;
}

#bio_ep_close {
    padding: 10px;
    float: right;
}

.product-item-info {
    width: auto !important;
}




