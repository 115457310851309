.footer {
  background: url(../images/stopka.jpeg) no-repeat rgba(242, 242, 242, 1);
  background-size: 100% auto;
  background-position: top;

  h3 {
    font-weight: 500;
    border-bottom: 1px darkgray dotted;
    font-size: 25px;
  }
  &-margin{
    overflow: hidden;
    height: 5px;
    @media (min-width: 992px) {
      height:3px;

    }
    @media (min-width: 1280px) {
      height:auto;

    }
  }
  &-image {
    max-width: 200px;
    margin-left: -23px;
    @media (min-width: 992px) {
      margin-left: 0;
    }
  }

  &-cert {
    max-width: 70px;
    margin-right: 10px;
  }

  .list-inline {
    margin-left: 0;
    padding-left: 0;
    list-style: none;
    padding-bottom: 0;

    li {
      margin: 0;

      a {
        font-size: 14px;
      }
    }
  }

  a {
    font-family: 'Amatic SC', "Open Sans", sans-serif;
  }

  &-left-block {
    @media (min-width: 992px) {

      margin-left: 23px;
    }
  }
}
